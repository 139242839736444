<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="news-content" v-loading="loading">
            <div class="title">{{newsInfo.adTitle}}</div>
            <div class="info">
                <span class="date">时间：{{newsInfo.adDate}}</span>
                <span class="source">来源：{{newsInfo.source}}</span>
                <span class="count">浏览量：{{newsInfo.views}}</span>
            </div>
            
            <div class="desc" v-html="newsInfo.adContent" id="desc"></div>
        </div>
    </div>
</template>

<script>
import Bread from '../../../components/common/Bread.vue'
import { useRoute} from 'vue-router'
import { ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const zoneId = ref(query.zoneId);

        console.log(query)

        const breads = ref([{text:'首页', path: '/index', name: 'Index'}]);
        breads.value = [{text: '产业专区', path: '/industry', name: 'Industry'}];
      
    
        function getDetail(){
            loading.value = true;
            const url = '/hfapplication/gdsti/a/adInfo/getAdInfoById';
            proxy.$http.post(url, {id: query.id}).then(res=>{
                loading.value = false;
                if(res.data.state == 0){
                    console.log(newsInfo)
                    newsInfo.value = res.data.result;
                    let typeName = newsInfo.value.adTypeName;
                    let typeId = newsInfo.value.adType;
                    
                    breads.value.push({text: typeName, name:'IndustryPlatformList', path:'/industryPlatformList?id='+ typeId + '&name='+ typeName + '&type='+type.value + '&zoneId='+ zoneId.value});
                    
                    breads.value.push({text: '文章详情'})

                    increase();
                    
                }
            }).catch(err=>{
                loading.value = false;
            })
        }

        function increase(){
            const url = '/hfapplication/gdsti/a/adInfo/addViews';
            proxy.$http.post(url, {id: query.id}).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{
            getDetail();
        })

        return {
            newsInfo, breads, loading
        }
    },
    components: {Bread},
    mounted(){
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = '/static/ueditor/ueditor.parse.min.js'
    //     document.body.appendChild(script);
    //     // console.log('mounted')
    //     uParse('#desc', {
    //         rootPath: "/static/ueditor";
    //     })
    }
 
}
</script>

<style lang="less" scoped>
.news-content{
    margin-top: 12px;
    margin-bottom: 60px;
    width: 1200px;
    overflow: auto;
    img{
        max-width: 1200px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    .desc{
        margin-top: 25px;
    }
}
</style>

