<template>
    <div class="news-bread">
            <template v-for="(item, index) in breads">
                <a class="bread-item" href="javascript:void(0);" @click="gotoBread(item)" v-if="index < breads.length-1">
                    {{item.text}}
                </a>
                <span class="bread-split" v-if="index < breads.length-1">/</span>
                <a v-else class="bread-item disabled" >{{item.text}}</a>
            </template>
            
    </div>
</template>

<script>
export default {
    props:{
         breads:{
            type: Array,
            default: [{
                name: '',
                text: '',
                path: ''
            }]
        }
    },
    methods:{
        gotoBread(item){
            let path = item.path;
            if(path.indexOf('?') >= 0){
                const [hash, query] = path.split('?');
                if(query){
                    item.query = {};
                    const strs = query.split('&');
                    for (let i = 0; i < strs.length; i++) {
                        const [key, value] = strs[i].split('=')
                        item.query[key] = value
                    }
                }
            }
            this.$router.push(item);
        }
    }
}
</script>


<style lang="less" scoped>
.news-bread{
    .bread-item,.bread-split{
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        letter-spacing: 0;
        text-align: justify;
        text-decoration: none;
        line-height: 42px;
    }
    .bread-split{
        margin-left: 4px;
        margin-right: 4px;
    }

    .disabled, .bread-item:hover{
        color: rgba(0,0,0,0.85);
    }
}
</style>
